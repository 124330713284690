import React from "react";
import ExeInstalled from "../../Icons/exeInstall.png";

const PinExe = ({ onSkip }) => {
  return (
    <div className="w-[880px] flex flex-col">
      <PinResalaHeader />
      <div className="absolute right-[160px] top-[40px]">
        <PinArrow />
      </div>
      <div className="mt-[40px] w-full bg-white rounded-[16px] flex flex-col items-center p-[40px]">
        <img src={ExeInstalled} alt="ExeInstalled" className="min-h-[421px]" />
        <button
          onClick={onSkip}
          className="text-[18px] leading-[24px] font-bold text-LightPrimaryActive bg-transparent mt-[40px] self-end"
        >
          Skip
        </button>
      </div>
    </div>
  );
};

export default PinExe;

const PinArrow = () => (
  <p className="flex ml-[4px] text-[22px] leading-[28px] font-semibold">
    Click
    <span className="mx-[8px]">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="none"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M9.48273 -0.00390625C7.72575 -0.00390625 6.27879 1.32372 6.08981 3.03075H2.2003H0V5.23105V6.91735V9.12579C0.124491 9.11203 0.250999 9.10497 0.379157 9.10497C0.507315 9.10497 0.633824 9.11203 0.758315 9.12579C2.46528 9.31446 3.79298 10.762 3.79298 12.5197C3.79298 14.2774 2.46528 15.7249 0.758315 15.9136C0.633824 15.9273 0.507315 15.9344 0.379157 15.9344C0.250999 15.9344 0.124491 15.9273 0 15.9136V18.122V19.801V22.0013H2.2003H16.7654H18.9657V19.801V15.9062C20.6726 15.7175 22.0001 14.27 22.0001 12.5124C22.0001 10.7547 20.6726 9.30727 18.9657 9.1185V5.23105V3.03075H16.7654H12.8756C12.6867 1.32372 11.2397 -0.00390625 9.48273 -0.00390625ZM16.7654 5.23105H2.2003V7.20667C4.40725 7.96318 5.99328 10.0565 5.99328 12.5197C5.99328 14.9829 4.40725 17.0762 2.2003 17.8327V19.801H16.7654V5.23105Z"
          fill="#19224C"
        />
      </svg>
    </span>
    in the toolbar
    <span className="relative w-[44px] ml-[4px]">
      <svg
        className="absolute bottom-[10px]"
        xmlns="http://www.w3.org/2000/svg"
        width="44"
        height="31"
        viewBox="0 0 44 31"
        fill="none"
      >
        <path
          d="M0.992021 27.2466C19.9729 33.7942 34.8864 19.8805 40.14 3.34773C36.2421 4.65725 32.3443 6.13046 28.277 7.43999C26.7517 7.93106 26.0738 5.63939 27.5991 5.14832C32.1748 3.18403 36.7506 1.71082 41.3263 0.073913C42.1737 -0.253468 43.021 0.564985 43.021 1.38344C43.021 2.0382 43.021 2.85665 43.021 3.51142C44.2073 7.93106 44.3768 11.6959 43.1905 16.1156C42.8515 17.7525 40.6484 17.2614 40.6484 15.9519C40.6484 13.8239 40.4789 11.8596 40.4789 9.73166C33.3611 24.6275 17.9392 36.2495 0.314134 28.5561C-0.363753 28.2287 0.144662 26.9192 0.992021 27.2466Z"
          fill="#19224C"
        />
      </svg>
    </span>
  </p>
);

const PinResalaHeader = () => (
  <div className="flex flex-col items-center text-LightPrimary gap-[12px]">
    <p className="text-[30px] leading-[40px] font-bold text-LightPrimary">
      Pin Resala for quick access
    </p>
    <p className="flex text-[18px] leading-[24px] font-medium">
      Click
      <span className="mx-[4px]">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="21"
          height="21"
          viewBox="0 0 21 21"
          fill="none"
        >
          <g clip-path="url(#clip0_12432_103760)">
            <path
              d="M5.6825 0.682501C5.74065 0.624499 5.80966 0.578526 5.88559 0.547211C5.96151 0.515897 6.04287 0.499854 6.125 0.500001H14.875C15.0408 0.500001 15.1997 0.565849 15.3169 0.683059C15.4342 0.80027 15.5 0.959241 15.5 1.125C15.5 1.975 15.0725 2.5925 14.6925 2.97375C14.535 3.13 14.38 3.25375 14.25 3.34625V8.885L14.3475 8.945C14.6012 9.10375 14.9425 9.3375 15.2863 9.63875C15.95 10.2188 16.75 11.1588 16.75 12.375C16.75 12.5408 16.6842 12.6997 16.5669 12.8169C16.4497 12.9342 16.2908 13 16.125 13H11.125V18.625C11.125 18.97 10.845 20.5 10.5 20.5C10.155 20.5 9.875 18.97 9.875 18.625V13H4.875C4.70924 13 4.55027 12.9342 4.43306 12.8169C4.31585 12.6997 4.25 12.5408 4.25 12.375C4.25 11.1588 5.05 10.2188 5.7125 9.63875C6.03618 9.35834 6.38328 9.10617 6.75 8.885V3.34625C6.59313 3.23366 6.44519 3.10912 6.3075 2.97375C5.9275 2.5925 5.5 1.97375 5.5 1.125C5.49985 1.04287 5.5159 0.961515 5.54721 0.885588C5.57853 0.809661 5.6245 0.74065 5.6825 0.682501Z"
              fill="#19224C"
            />
          </g>
          <defs>
            <clipPath id="clip0_12432_103760">
              <rect
                width="20"
                height="20"
                fill="white"
                transform="translate(0.5 0.5)"
              />
            </clipPath>
          </defs>
        </svg>
      </span>
      next to Resala.
    </p>
  </div>
);
