import React from "react";
import { useTranslation } from "react-i18next";
import SpinnerLoader from "../../../../utils/LoaderCommon";
import { CloseEyeIcon, EyeIcon } from "../AccoutSetting/UpdatePassword";
import { ButtonExeFilled, ButtonOutLine } from "../Button";
import InputField, { ErrorMsg } from "../InputField";

const VerifyPassword = ({
  isLoading,
  passMeta,
  setPassMeta,
  onClose,
  onSuccess,
}) => {
  const { t, i18n } = useTranslation();

  const handleChange = (e) => {
    setPassMeta({ ...passMeta, errorMsg: undefined, password: e.target.value });
  };

  return (
    <div className="w-[400px]">
      <InputField
        name="password"
        onChange={handleChange}
        className={"mt-[16px]"}
        value={passMeta.password}
        inputCss="px-[18px] py-[15px]"
        // placeholder={t("password")}
        floatLabel={t("password")}
        isFilled={passMeta?.password?.length > 0}
        type={passMeta.isPassShow ? "text" : "password"}
        error={passMeta?.errorMsg?.password}
        suffix={
          <div
            onClick={() =>
              setPassMeta((prev) => ({
                ...prev,
                isPassShow: !prev.isPassShow,
              }))
            }
            className="min-w-[25px]"
          >
            {passMeta.isPassShow ? <CloseEyeIcon /> : <EyeIcon />}
          </div>
        }
      />
      {passMeta.errorMsg && <ErrorMsg errorMsg={passMeta.errorMsg} />}
      <div className="flex gap-x-[20px] mt-[20px]">
        <ButtonOutLine
          height={40}
          text={t("cancel")}
          fontColor={"#5F6583"}
          borderColor={"#DFE4EC"}
          onClick={() => {
            onClose();
          }}
          hoverCss={
            "hover:!text-LightPrimaryActive hover:!bg-[#eef6ff] hover:!border-[#D3E7FF]"
          }
        />
        <ButtonExeFilled
          height={40}
          text={isLoading ? <SpinnerLoader /> : t("confirm")}
          isDisable={isLoading || passMeta.errorMsg}
          fontColor={"#FFFFFF"}
          onClick={() => onSuccess(passMeta)}
          hoverCss={"hover:!bg-DarkHover"}
        />
      </div>
    </div>
  );
};

export default VerifyPassword;
