import { useContext, useState } from "react";
import { GearIcon } from "../../Icons/Icons";
import { SettingCard, SettingRow } from "../../Pages/AccountSetting/SettingRow";
import { ButtonOutLine } from "../Button";
import ModalBaseLayout from "../Modal";
import TitleComp from "../TitleComp";
import DeleteAccount from "./DeleteAccount";
import LoadingContext from "../../Context/LoadingContext";
import Skeleton from "react-loading-skeleton";
import { t } from "i18next";

const AccountManagment = ({ userData }) => {
  const { email } = userData;
  const {
    loading: { isAccountsPage },
  } = useContext(LoadingContext);
  const [isDeleteModal, setIsDeleteModal] = useState(false);

  const openDeleteModal = () => setIsDeleteModal(true);
  const closeDeleteModal = () => setIsDeleteModal(false);

  return (
    <>
      <TitleComp icon={<GearIcon />} title={t("account_management")} />
      <SettingCard>
        <SettingRow
          title={
            isAccountsPage ? (
              <Skeleton height={18} width={120} />
            ) : (
              t("delete_account")
            )
          }
          desc={isAccountsPage ? <Skeleton height={18} width={140} /> : email}
          action={
            isAccountsPage ? (
              <Skeleton height={40} width={134} />
            ) : (
              <ButtonOutLine
                onClick={openDeleteModal}
                fontColor="#FF0000"
                borderColor={"#FF0000"}
                width="90px"
                text={t("delete")}
                height={"40px"}
                hoverCss={
                  "hover:bg-LightGray hover:!text-[#D90000] hover:border-[#D90000]"
                }
              />
            )
          }
        />
      </SettingCard>

      <ModalBaseLayout
        isPreventOutsideClick
        overflow={"hidden"}
        title=""
        isOpen={isDeleteModal}
        setClose={closeDeleteModal}
      >
        <DeleteAccount
          onClose={closeDeleteModal}
          email={email}
          userData={userData}
        />
      </ModalBaseLayout>
    </>
  );
};

export default AccountManagment;
