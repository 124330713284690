import { t } from "i18next";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import GradientBg from "../../Components/GradientBg";
import { fontCommons } from "./Components/AccoutSetting/UpdateEmail";
import { TwoToneText } from "./Components/AccoutSetting/UpdateEmail-old";
import { ButtonExeFilled } from "./Components/Button";
import InputField from "./Components/InputField";
import { cn } from "./Components/utils";
import { ResalaLogo } from "./Icons/Icons";
import { ArabicLogo } from "./layout/Sidebar";
import Otp from "./Otp";
import SpinnerLoader from "../../utils/LoaderCommon";
import {
  loginWithFacebook,
  OtpCheck,
  registerFBSendOtp,
} from "../../api/auth/auth";
import { useDetectCountry } from "./customHook/useDetectCountry";

const initialError = {
  email: undefined,
  otp: undefined,
};

const ConnectEmail = () => {
  const {
    i18n: { language },
  } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const isFromExe = searchParams.get("fr");
  const userBrowserCountry = useDetectCountry();

  const FB_USER_META = JSON.parse(localStorage.getItem("fb_meta"));

  const [currentStep, setCurrentStep] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [isResendOtp, setIsResendOtp] = useState(false);
  const [isOtpSented, setIsOtpSented] = useState(false);
  const [errMsg, setErrMsg] = useState(initialError);
  const [inputData, setInputData] = useState({
    email: "",
    otp: "",
  });

  const handleContinue = () => {
    switch (currentStep) {
      case 1:
        handleSendOtp();
        break;
      case 2:
        verifyOtp();
        break;
      default:
        return;
    }
  };

  const handleSendOtp = async () => {
    setIsResendOtp(true);
    setInputData({ ...inputData, otp: "" });
    setErrMsg({ ...errMsg, otp: "" });
    try {
      const res = await registerFBSendOtp({ email: inputData.email.trim() });
      if (res?.data?.Error || res.detail) {
        setIsResendOtp(false);
        setErrMsg({ ...errMsg, email: res?.data?.Message });
        return;
      }
      setIsResendOtp(false);
      setIsOtpSented(true);
      setCurrentStep(2);
    } catch (error) {
      console.log(error);
      setIsResendOtp(false);
    }
  };

  const sendTokenToExe = ({ access_token, ...rest }) => {
    window.postMessage(
      { type: "TOKEN_PASS", access_token: access_token, ...rest },
      "*"
    );
  };

  const facebookLogin = async () => {
    const { name, picture, userID } = FB_USER_META;
    const payload = {
      email: inputData.email,
      name: name,
      facebook_id: userID,
      profile_picture: picture.data.url,
      country_name: userBrowserCountry ?? "US",
    };
    try {
      const res = await loginWithFacebook(payload);
      if (res.Error || res.detail) {
        console.log(res);
        return;
      }
      setIsLoading(true);
      const { access_token } = res.Result || {};
      sendTokenToExe(res.Result);
      localStorage.setItem("token", JSON.stringify(access_token));
      // navigate(`profile/`);
      window.location.href = "profile";
      // if (redirectTo) {
      //   window.location.href = `/${redirectTo}`;
      // } else {
      //   window.location.href = "profile";
      // }
      // navigate(`/auth/${access_token}`);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const verifyOtp = async () => {
    setIsLoading(true);
    try {
      const res = await OtpCheck({
        otp: inputData.otp,
        email: inputData.email.trim(),
      });
      if (res.data?.Error) {
        setIsLoading(false);
        setErrMsg({ ...errMsg, otp: res.data.Message });
        return;
      }
      setIsLoading(false);
      if (isFromExe) {
        window.postMessage(
          {
            type: "FACEBOOK_LOGIN",
            data: { ...FB_USER_META, email: inputData.email.trim() },
          },
          "*"
        );
      } else {
        setCurrentStep(3);
        facebookLogin();
      }
    } catch (error) {
      setIsLoading(false);
      console.log("error");
    }
  };

  const handleBack = () => {
    switch (currentStep) {
      case 1:
        navigate("/login");
        break;
      case 2:
        setCurrentStep(1);
        setInputData({ ...inputData, otp: "" });
        break;
      case 3:
        setCurrentStep(2);
        break;
      default:
        return;
    }
  };

  const isArabic = language === "ar";

  return (
    <GradientBg>
      <div className="w-full flex justify-center items-center min-h-screen">
        {/* SECTION 1 */}
        <div
          style={{ boxShadow: "0px 4px 60px 0px #3C425714" }}
          className="w-[600px] h-fit rounded-[20px] bg-white py-[30px] px-[60px] flex flex-col items-center"
        >
          <ResalaLogo
            onClick={() => navigate("/")}
            style={{ display: currentStep === 3 && "none" }}
            className={cn(
              "min-h-[40px] cursor-pointer",
              !isArabic ? "block" : "hidden"
            )}
          />
          <ArabicLogo
            onClick={() => navigate("/")}
            style={{ display: currentStep === 3 && "none" }}
            className={cn(
              "min-h-[40px] cursor-pointer",
              isArabic ? "block" : "hidden"
            )}
          />

          {currentStep === 1 && (
            <>
              <p className="mt-[30px] text-LightPrimary text-center text-[22px] font-bold">
                {t("connect_email")}
              </p>

              <div className="pt-[20px] w-full">
                <p className="text-[14px] leading-[18px] text-LightStrokActive text-center">
                  {t("email_verify")}
                </p>
                <InputField
                  name="email"
                  type={"text"}
                  className={"mb-16px mt-[30px]"}
                  inputCss="px-[18px] py-[15px]"
                  value={inputData.email}
                  isDisabled={isLoading || isResendOtp}
                  onChange={(e) => {
                    setErrMsg(initialError);
                    setInputData((prev) => ({
                      ...prev,
                      email: e.target.value,
                    }));
                  }}
                  placeholder={t("email_address")}
                  error={errMsg.email}
                  // suffix={
                  //   currentStep === 2 && (
                  //     <div
                  //       className="min-w-25px text-LightPrimaryActivefont-normal"
                  //       onClick={() => {
                  //         setCurrentStep(1);
                  //       }}
                  //     >
                  //       {t("edit")}
                  //     </div>
                  //   )
                  // }
                />
                <div className="flex flex-col gap-x-[20px] mt-[24px]">
                  <ButtonExeFilled
                    height={52}
                    isDisable={
                      isLoading || isResendOtp || inputData.email.length === 0
                    }
                    text={
                      isLoading || isResendOtp ? (
                        <SpinnerLoader />
                      ) : (
                        t("continue")
                      )
                    }
                    fontColor={"#FFFFFF"}
                    onClick={handleContinue}
                    hoverCss={"hover:!bg-DarkHover"}
                  />
                  <ButtonExeFilled
                    type="button"
                    height={52}
                    isDisabled={isLoading || isResendOtp}
                    borderColor={"transparent"}
                    backgroundColor={"transparent"}
                    hoverCss={
                      "text-[#1678F2] hover:text-[#1263CB] !text-[14px] leading-[16px] font-bold"
                    }
                    onClick={handleBack}
                    text={t("go_back")}
                  ></ButtonExeFilled>
                </div>
              </div>
            </>
          )}

          {currentStep === 2 && (
            <div className="w-full">
              <p className="mt-[30px] text-LightPrimary text-center text-[22px] font-bold">
                {t("enter_code")}
              </p>
              <div className="flex justify-center mt-[8px] pb-[30px]">
                <TwoToneText
                  fontCommons={fontCommons}
                  text1={t("enter_code_sent")}
                  text2={inputData.email}
                />
              </div>
              <InputField
                type={t("number")}
                maxlength={6}
                inputCss="text-center h-[52px]"
                // placeholder={t("enter_otp")}
                placeholder={"000 000"}
                value={inputData.otp || ""}
                error={errMsg.otp}
                onChange={(e) => {
                  setErrMsg(initialError);
                  if (e.target.value?.length > 6) return;
                  setInputData((prev) => ({
                    ...prev,
                    otp: e.target.value,
                  }));
                }}
              />
              <Otp
                startTimer={isOtpSented}
                resendOtp={() => {
                  setIsOtpSented(false);
                  handleSendOtp();
                }}
              />
              <div className="flex flex-col gap-x-[20px] mt-[20px]">
                <ButtonExeFilled
                  height={52}
                  isDisable={
                    isLoading || isResendOtp || inputData.otp.length === 0
                  }
                  text={isLoading ? <SpinnerLoader /> : t("continue")}
                  fontColor={"#FFFFFF"}
                  onClick={handleContinue}
                  hoverCss={"hover:!bg-DarkHover"}
                />
                <ButtonExeFilled
                  type="button"
                  height={52}
                  isDisabled={isLoading}
                  borderColor={"transparent"}
                  backgroundColor={"transparent"}
                  hoverCss={
                    "text-[#1678F2] hover:text-[#1263CB] !text-[14px] leading-[16px] font-bold"
                  }
                  onClick={handleBack}
                  text={t("go_back")}
                ></ButtonExeFilled>
              </div>
            </div>
          )}

          {currentStep === 3 && (
            <div className="flex flex-col justify-center w-full">
              <div className="flex items-center justify-center gap-[8px] mb-[12px]">
                <SuccessIcon className="cursor-pointer h-[64px] w-[64px]" />
              </div>
              <div className="text-[22px] flex justify-center mb-[8px] font-bold text-LightPrimary">
                {t("successfullyLogin")}
              </div>
              <div className="flex justify-center px-[10px] text-center text-[#6D77A0] flex-col text-[14px] gap-[8px]">
                {t("welcome_msg")}
              </div>
            </div>
          )}
        </div>
      </div>
    </GradientBg>
  );
};

export default ConnectEmail;

const SuccessIcon = (props) => (
  <svg
    {...props}
    width="64"
    height="64"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="32" cy="32" r="32" fill="#23B339" fill-opacity="0.15" />
    <path
      d="M32 12C20.98 12 12 20.98 12 32C12 43.02 20.98 52 32 52C43.02 52 52 43.02 52 32C52 20.98 43.02 12 32 12ZM41.56 27.4L30.22 38.74C29.94 39.02 29.56 39.18 29.16 39.18C28.76 39.18 28.38 39.02 28.1 38.74L22.44 33.08C21.86 32.5 21.86 31.54 22.44 30.96C23.02 30.38 23.98 30.38 24.56 30.96L29.16 35.56L39.44 25.28C40.02 24.7 40.98 24.7 41.56 25.28C42.14 25.86 42.14 26.8 41.56 27.4Z"
      fill="#23B339"
    />
  </svg>
);
