import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import RequestModal from "../../Components/RequestModal";
import { getResalaPlans, getUserPlan } from "../../Exe-api/subscription-api";
import { useWindowSize } from "../../hooks/useWindowsize";
import Layout from "../../utils/Layout";
import MobileViewContent from "./MobileViewContent";
import PricingFaq from "./PricingFaq";
import WebView from "./WebViewContent";
import { pricingOptions } from "./jsons";
import i18n from "../../i18";
// import { SplashLoader } from "../../utils/LoaderCommon";

const Pricing = ({ referrer }) => {
  const { width: windowWidth } = useWindowSize();
  const { t } = useTranslation();
  const [isModalOpen, setModalOpen] = useState(false);
  const [allPlans, setAllPlans] = useState([]);
  const [userData, setUserData] = useState(undefined);
  // const [selected, setSelected] = useState(pricingOptions[0]);
  // const [selectedPlan, setSelectedPlan] = useState(SubPlans[0]);
  const [selected, setSelected] = useState(undefined);
  const [selectedPlan, setSelectedPlan] = useState(undefined);
  const token = JSON.parse(localStorage.getItem("token"));

  const toggleModal = () => setModalOpen(!isModalOpen);
  const hideModal = () => setModalOpen(false);

  useEffect(() => {
    async function fetchPlans() {
      try {
        const res = await getResalaPlans();
        if (res.Error || res.detail) {
          return;
        }
        setAllPlans(res.offers);
      } catch (err) {
        console.log(err);
      }
    }

    fetchPlans();
  }, []);

  useEffect(() => {
    if (!token) return;
    const getPlanUser = async () => {
      try {
        const res = await getUserPlan({ token: `Bearer ${token}` });
        if (res.Error || res.detail || res === "Something went wrong") {
          return;
        }
        setUserData(res);
      } catch (err) {
        console.log(err);
      }
    };
    getPlanUser();
  }, []);

  useEffect(() => {
    if (allPlans && userData) {
      const { subscription_id, plan_duration_id } = userData;
      const freqObj = pricingOptions?.find((e) => e.id === plan_duration_id);
      if (!freqObj) return;
      setSelected(freqObj);
      const curentPlan = allPlans[freqObj.val]?.find(
        (p) => p.id === subscription_id
      );
      setSelectedPlan(curentPlan);
    }
  }, [allPlans, userData]);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const lang = queryParams.get("lang");
    const abc = document.getElementById("main-app");
    if (lang === "ar") {
      localStorage.setItem("lang", "ar");
      i18n.changeLanguage("ar");
      abc.dir = "rtl";
    } else {
      localStorage.setItem("lang", "en");
      i18n.changeLanguage("en");
      abc.dir = "ltr";
    }
  }, []);

  const isTablateView = windowWidth <= 1300;

  // if (allPlans?.length === 0) {
  //   return (
  //     <div></div>
  //     // <div className="h-screen w-full flex justify-center items-center">
  //     //   {/* <img width={300} height={300} src={Loader} alt="" /> */}
  //     //   <SplashLoader />
  //     // </div>
  //   );
  // }

  return (
    <>
      <Layout
        isModalOpen={isModalOpen}
        setModalOpen={setModalOpen}
        hideModal={hideModal}
        toggleModal={toggleModal}
        referrer={referrer}
      >
        <PageHeading title={t("PricingTitle")} desc={t("pricingDiscription")} />

        {isTablateView && (
          <MobileViewContent
            allPlans={allPlans}
            selected={selected}
            setSelected={setSelected}
            selectedPlan={selectedPlan}
            setSelectedPlan={setSelectedPlan}
            activeSubscription={userData}
          />
        )}

        {!isTablateView && (
          <WebView
            allPlans={allPlans}
            selected={selected}
            setSelected={setSelected}
            selectedPlan={selectedPlan}
            setSelectedPlan={setSelectedPlan}
            activeSubscription={userData}
          />
        )}

        {/* FAQ */}
        <div className="mt-[150px] max_xl:mt-[60px]">
          <PageHeading title={t("FAQ")} />
          <div className="mt-[100px] max-w-[1040px] w-full m-auto max_xl:mt-[30px]">
            <PricingFaq />
          </div>
        </div>
      </Layout>

      <RequestModal
        isModalOpen={isModalOpen}
        setModalOpen={setModalOpen}
        toggleModal={toggleModal}
        hideModal={hideModal}
        referrer={referrer}
      />
    </>
  );
};

export default Pricing;

const PageHeading = ({ title, desc = "" }) => (
  <div className="w-full max-w-[1060px] m-auto text-center mt-[40px] px-[20px]">
    <div className="priceHeading mb-[20px]">{title}</div>
    <div className="priceSubHeading mt-[20px] ">{desc}</div>
  </div>
);
