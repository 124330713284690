import React, { useEffect, useLayoutEffect, useState } from "react";
import GradientBg from "../../Components/GradientBg";
import PinExe from "./utils/Welcome/PinExe";
import { ResalaLogo } from "./Icons/Icons";
import FeaturesTour from "./utils/Welcome/FeaturesTour";
import { authenticateUser } from "../../Exe-api/apis";
import { useNavigate } from "react-router-dom";

import Chat from "./utils/Welcome/featureImages/Chat.png";
import CreateImage from "./utils/Welcome/featureImages/CreateImage.png";
import DocumentChat from "./utils/Welcome/featureImages/DocumentChat.png";
import EmailReply from "./utils/Welcome/featureImages/EmailReply.png";
import Grammar from "./utils/Welcome/featureImages/Grammar.png";
import ImageChat from "./utils/Welcome/featureImages/ImageChat.png";
import QuickCompose from "./utils/Welcome/featureImages/QuickCompose.png";
import Translation from "./utils/Welcome/featureImages/Translation.png";
import VideoSummary from "./utils/Welcome/featureImages/VideoSummary.png";
import WebSummary from "./utils/Welcome/featureImages/WebSummary.png";

const ExeTour = () => {
  const navigate = useNavigate();
  const [step, setStep] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const valiadateUser = async (token) => {
    setIsLoading(true);
    try {
      const res = await authenticateUser({ token: token });

      if (res.Error || res.detail || res.message) {
        setIsLoading(false);
        return false;
      }
      return false;
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      return false;
    }
  };

  useEffect(() => {
    let interval = setInterval(checkIsPinned, 5);
    async function checkIsPinned() {
      const isPinned = sessionStorage.getItem("isPinned");
      if (isPinned == "true") {
        setStep(1);
        clearInterval(interval);
      }
    }
    return () => {
      clearInterval(interval);
    };
  }, []);

  useLayoutEffect(() => {
    const isPinned = sessionStorage.getItem("isPinned");
    if (isPinned === "true") {
      setStep(1);
    }
  }, []);

  const FeatureList = [
    {
      id: 1,
      name: "AI Chat",
      img: Chat,
    },
    {
      id: 2,
      name: "Web Summary",
      img: WebSummary,
    },
    {
      id: 3,
      name: "Document Chat",
      img: DocumentChat,
    },
    {
      id: 4,
      name: "Chat with Image",
      img: ImageChat,
    },
    {
      id: 5,
      name: "Create Image",
      img: CreateImage,
    },
    {
      id: 6,
      name: "Video Summary",
      img: VideoSummary,
    },
    {
      id: 7,
      name: "Email Replies",
      img: EmailReply,
    },
    {
      id: 8,
      name: "Quick Compose",
      img: QuickCompose,
    },
    {
      id: 9,
      name: "Grammar & Spelling Correction",
      img: Grammar,
    },
    {
      id: 10,
      name: "Translation",
      img: Translation,
    },
  ];

  return (
    <GradientBg>
      <div className="w-full flex justify-center items-center min-h-screen">
        {/* <CardWrapper> */}
        {/* <div className="grid grid-cols-2 h-full">
            <WelcomeComp />
            <TourContent />
            </div> */}
        {/* </CardWrapper> */}
        <TourHeader />
        {step === 0 && <PinExe onSkip={() => setStep(1)} />}
        {step === 1 && (
          <FeaturesTour
            FeatureList={FeatureList}
            onStartResala={async () => {
              const authTokenFirst = JSON.parse(localStorage.getItem("token"));
              const isValid = await valiadateUser(authTokenFirst);
              if (isValid) {
                window.postMessage(
                  {
                    type: "TOKEN_PASS",
                    access_token: authTokenFirst,
                  },
                  "*"
                );
              }
              window.postMessage(
                {
                  type: "OPEN_EXE",
                },
                "*"
              );
              // navigate("/");
            }}
          />
        )}
      </div>
    </GradientBg>
  );
};

export default ExeTour;

const TourHeader = () => (
  <div className="absolute top-0 left-0 right-0">
    <div className="w-full h-[80px] mx-[100px] flex items-center">
      <ResalaLogo />
    </div>
  </div>
);
