import React, { useState } from "react";
import { cn } from "../../Components/utils";

import { ButtonFilled } from "../../../../Components/Button";

const FeaturesTour = ({ onStartResala, FeatureList }) => {
  const [currentStep, setCurrentStep] = useState(FeatureList[0]);

  return (
    <div className="flex flex-col">
      <div
        style={{
          boxShadow: "0px 8px 60px 0px #3C42571A",
        }}
        className="w-fit overflow-hidden flex bg-white rounded-[16px]"
      >
        <div className="p-[20px] xl:p-[40px] flex flex-col w-[345px]">
          <p className="text-[20px] mb-[16px] leading-[26px] font-semibold text-LightPrimary">
            Resala Features
          </p>

          {FeatureList.map((feature) => (
            <button
              className="flex py-[8px] hover:bg-LightBgPrimary rounded-[7px]"
              onClick={() => setCurrentStep(feature)}
            >
              <div className="w-[24px] min-w-[24px] h-full flex justify-center items-center">
                <div
                  // style={{ background: LightPrimary }}
                  className={cn(
                    "w-[4px] h-[4px] rounded-full",
                    feature.id === currentStep.id
                      ? "bg-LightPrimary"
                      : "bg-LightTextParagraph"
                  )}
                />
              </div>
              <p
                className={cn(
                  "m-0 text-[16px] leading-[20px] text-left",
                  feature.id === currentStep.id
                    ? "text-LightPrimary font-semibold"
                    : "text-LightTextParagraph font-medium"
                )}
                id={feature.id}
              >
                {feature.name}
              </p>
            </button>
          ))}
        </div>
        <div className="bg-LightGray flex justify-center items-center">
          <img src={currentStep.img} alt="img" className="min-w-[820px]" />
        </div>
      </div>
      <ButtonFilled
        onClick={onStartResala}
        className="mt-[40px] w-[600px] mx-auto"
        title="Start Using Resala"
        height={52}
      />
    </div>
  );
};

export default FeaturesTour;
